//import { Component } from '@angular/core';
import { Component, OnInit, HostListener, Inject } from '@angular/core'; 
//import {formatDate } from '@angular/common';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from 'src/app/alert/alert.component';
import { AutoLogoutService } from './auto-logout.service';
import { LawyerinfoService } from './lawyerinfo.service';
import {HttpClient} from '@angular/common/http';


//import { Http, Response, Headers, RequestOptions } from '@angular/http';
//import { LawyerinfoService } from '../../../lawyerinfo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AutoLogoutService]
})
export class AppComponent {

  remoteAddress:any;
  remoteAddressData:any = [];
  errorMessage: string;

  public dateValue: Date = new Date();
  title = 'Siddique Enterprise Limited';
  is_loggedin: boolean = false;
  is_admin: number = 0;
  
  constructor(
    private http: HttpClient,
    private SimpleModalService: SimpleModalService,
    private autoLogoutService: AutoLogoutService,
    public lawyerinfo_api:LawyerinfoService, 
    
) {
  this.http.get<{ip:string}>('https://jsonip.com')
    .subscribe( data => {
      //console.log('th data', data);
      this.remoteAddress = data;
      this.getRemoteAddress();

    }) 
}




  ngOnInit() {

    if(this.loggedIn()){
      this.is_loggedin = true;

      localStorage.setItem('lastAction',Date.now().toString());

    } else {
      this.is_loggedin = false;
    }

    if(this.isAdminForMenu()){
      this.is_admin = 1;
    } else {
      this.is_admin = 0;
    }

  }

  getRemoteAddress() {
   // console.log(this.remoteAddress.ip);
    let postData = {
      //AllremoteAddress: this.remoteAddress,
      remoteAddress: this.remoteAddress.ip,

      //AllremoteAddress: HttpContext.Current.Request.ServerVariables["REMOTE_ADDR"],
    }
    
    this.lawyerinfo_api
            .getRemoteAddress(postData)
            .subscribe(
              remoteAddressData => {
                    this.remoteAddressData = remoteAddressData
                },
                error => {
                    this.errorMessage = <any>error
                }
            );
  }

  /*
  getadvocatealllist() {
    let postData = {
      code_no: this.code_no,
      name: this.name,
      phone: this.phone,
    }
    this.rest
        .getadvocatealllist(postData)
        .subscribe(
          advocatedata => {
              this.advocatedata = advocatedata
              this.isLoading = false
              this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }*/

/*
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 150) {
       let element = document.getElementById('navbar');
       element.classList.add('sticky');
     } else {
      let element = document.getElementById('navbar');
        element.classList.remove('sticky'); 
     }
  }*/

  getUserName(): string{
    return localStorage.getItem('loggedin_username');
  }

  getLawyerCode(): string{
    return localStorage.getItem('loggedin_lawyercode');
  }

  isAdmin(): boolean{
    return localStorage.getItem('loggedin_ad_user') !==  "0";
  }

  isAdminForMenu(): boolean{
    return localStorage.getItem('loggedin_ad_user') !==  "0";
  }

  loggedIn(): boolean{
    return localStorage.getItem('access_token') !==  null;
  }  

  showAlert() {
    this.SimpleModalService.addModal(AlertComponent, {title: 'Please login to activate this menu.', message: 'If you are not a registered user, Please contact with Siddique Enterprise Ltd. \n\r Contact #   018   19   49   09   29'});
  }
}