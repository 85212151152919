import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LawyerinfoService } from 'src/app/lawyerinfo.service';

@Component({
  selector: 'app-organization-and-others',
  templateUrl: './organization-and-others.component.html',
  styleUrls: ['./organization-and-others.component.css']
})
export class OrganizationAndOthersComponent implements OnInit {

  
  organizationdata:any = [];
  code_no: any;
  name: any;
  phone: any;


  errorMessage: string;
  isLoading: boolean = true;
  isLoadingImage: boolean = true;

  constructor(
      public rest:LawyerinfoService, 
      private route: ActivatedRoute, 
      private router: Router
  ) { }

  ngOnInit() {
   this.getorganizationalllist(); 

   
   window.scrollTo({
    top: 1350,
    left: 0, 
    behavior: 'smooth'
  });
  }

  getorganizationalllist() {
    let postData = {
      code_no: this.code_no,
      name: this.name,
      phone: this.phone,
    }
    console.log(postData);

    this.rest
        .getorganizationalllist(postData)
        .subscribe(
          organizationdata => {
           // console.log(courtwisedata);
              this.organizationdata = organizationdata
              this.isLoading = false
              this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

}
