import { Component, OnInit } from '@angular/core';
import { LawyerinfoService } from '../../lawyerinfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { SimpleModalService } from 'ngx-simple-modal';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {


  notificationdata:any = [];
  notificationmodal:any = [];
  current_laywerCode: string;

  aid: string;
  division: string;


  errorMessage: string;
  isLoading: boolean = true;
  isLoadingImage: boolean = true;



showModal: boolean;
content: string;
title: string;

constructor(
  public rest:LawyerinfoService, 
  private route: ActivatedRoute, 
  private router: Router,
  public app:AppComponent,
  private SimpleModalService: SimpleModalService
) { 

  if (!this.app.loggedIn()) {
    this.router.navigate(['../']);
  }
}

  ngOnInit() {
    window.scrollTo({
      top: 1350,
      left: 0, 
      behavior: 'smooth'
    });
    this.current_laywerCode = this.app.getLawyerCode();
    this.getnotificationlist();
  }

  getnotificationlist() {
    let postData = {
      current_laywerCode:  this.current_laywerCode,
    }

    this.rest
        .getnotificationlist(postData)
        .subscribe(
          notificationdata => {
              this.notificationdata = notificationdata
              this.isLoading = false
              this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }


    showNotification(aid,division) {
      let postData = {
        aid: aid,
        division: division,
      }

    this.rest
        .showNotification(postData)
        .subscribe(
          notificationmodal => {
                this.notificationmodal = notificationmodal
                this.isLoading = false;
                this.showModal = true; // Show-Hide Modal Check
                this.title = "YOUR NOTIFICATION DETAILS";
             },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

  //Bootstrap Modal Close event
  hide()
  {
    this.showModal = false;
  }

}
