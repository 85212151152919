import { Component, OnInit } from '@angular/core';
import {formatDate} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.css']
})
export class LeftsidebarComponent implements OnInit {

  currentDate: Date = new Date();

  constructor() { }
  
  ngOnInit() {
  }

}
