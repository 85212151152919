import { Component, OnInit } from '@angular/core';
import { JusticelistService } from '../../../justicelist.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-justice-wise',
  templateUrl: './justice-wise.component.html',
  styleUrls: ['./justice-wise.component.css']
})
export class JusticeWiseComponent implements OnInit {

  //public seachjusticeDate: Date = new Date();
  
  selectedJusticeName: string = '';
  
  //event handler for the select element's change event
  selectChangeHandler (event: Event) {
    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    this.selectedJusticeName = selectedOptions[selectedIndex].text;
  }

  justicelistdata:any = [];
  justicewisedata:any = [];
  justicewiseCourtNamedata:any = [];
  seachjusticeDate: Date = new Date();
  justice_id:any;
  courtWiseJusticeNamedata:any = [];

  is_draft: number;

  errorMessage: string;
  isLoading: boolean = true;
  CourtName:boolean = false;
  ClickSearchMsg:boolean = true;
  detailsdata:boolean = false;

  isLoadingImage: boolean = false;

  errorMsg: boolean = false;
  errorMsg2: boolean = false;

  constructor(
    public rest:JusticelistService, 
    private route: ActivatedRoute, 
    private router: Router
  ) { }

  ngOnInit() {
    this.getJusticelist();

    
    window.scrollTo({
      top: 1350,
      left: 0, 
      behavior: 'smooth'
    });
  }


  getJusticelist() {
    //console.log("dd");
    /*this.courtlistdata = [];
    this.castoday$ = this.rest.getCasetoday().subscribe((data: {}) => {
      console.log(data);
      this.courtlistdata = data;
    });*/

    let postData = {
      seachjusticeDate: this.seachjusticeDate,
    }
    //console.log(postData);

    this.rest
            .getJusticelist(postData)
            .subscribe(
              justice_lists => {
                    this.justicelistdata = justice_lists;
                    //console.log(this.justicelistdata)
                    this.isLoading = false
                },
                error => {
                    this.errorMessage = <any>error
                    this.isLoading = false
                }
            );
  }  

  onChange(value){
    //console.log(value);
    this.seachjusticeDate=value;
    this.getJusticelist();
  }
  getJusticewise() {


    if(this.justice_id === undefined || this.justice_id == ""){
      this.errorMsg2 = true;
      this.isLoading = true;
      return;
    } else if (this.seachjusticeDate === undefined || this.seachjusticeDate == null){
      this.errorMsg2 = false;
      this.errorMsg = true;
      this.isLoading = true;
      return;
    }

    this.errorMsg2 = false;
    this.errorMsg = false;


    let postData = {
      seachjusticeDate: this.seachjusticeDate,
      justice_id: this.justice_id,
    }
    //console.log(postData);


    this.isLoadingImage = true
    this.detailsdata = false

    this.rest
            .getDraftStatus(postData)
            .subscribe(
              is_draft => {
                    this.is_draft = is_draft
                }
            );   

    this.rest
        .getJusticewise(postData)
        .subscribe(
              groupPageArray => {
                //console.log(groupPageArray);
                  this.justicewisedata = groupPageArray
                  //console.log(this.justicewisedata);
                  this.isLoading = false
                  this.ClickSearchMsg = false
                  this.detailsdata = true
                  this.isLoadingImage = false
    
                },

            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImage = false
            }
        );
  }

  getjusticeWiseCourtName() {
    let postData = {
      seachjusticeDate: this.seachjusticeDate,
      justice_id: this.justice_id,
    }
    //console.log(postData);

    this.rest
        .getjusticeWiseCourtName(postData)
        .subscribe(
          justicewiseCourtNamedata => {
                this.justicewiseCourtNamedata = justicewiseCourtNamedata
                this.isLoading = false
                this.CourtName=true;
                this.ClickSearchMsg = false
                //this.detailsdata = true

            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }


  getCourtWiseJusticeName() {
    let postData = {
      seachjusticeDate: this.seachjusticeDate,
      justice_id: this.justice_id,
    }
    //console.log(postData);

    this.rest
        .getCourtWiseJusticeName(postData)
        .subscribe(
          courtWiseJusticeNamedata => {
                this.courtWiseJusticeNamedata = courtWiseJusticeNamedata
                this.isLoading = false 

            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }


}