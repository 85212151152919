import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.css']
})
export class OurServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    window.scrollTo({
      top: 1950,
      left: 0, 
      behavior: 'smooth'
    });
  }

}
