import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-highcourt',
  templateUrl: './highcourt.component.html',
  styleUrls: ['./highcourt.component.css']
})
export class HighcourtComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
    window.scrollTo({
      top: 1350,
      left: 0, 
      behavior: 'smooth'
    });
  }

}
