import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appellate',
  templateUrl: './appellate.component.html',
  styleUrls: ['./appellate.component.css']
})
export class AppellateComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    
    window.scrollTo({
      top: 1350,
      left: 0, 
      behavior: 'smooth'
    });
  }

}
