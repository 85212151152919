import { Component, OnInit } from '@angular/core';
import { AppellatedivisionService } from '../../../appellatedivision.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-total-case-list-appelate',
  templateUrl: './total-case-list-appelate.component.html',
  styleUrls: ['./total-case-list-appelate.component.css']
})
export class TotalCaseListAppelateComponent implements OnInit {

  totalcaselisthddata:any = [];
  totalcaselisthddatacount:any = [];
  totalcaselisthddetailsdata:any = [];
  totalcaselisttwdata:any = [];
  totalcasesummarylwdata:any = [];
 
  search_code:any;
  

  errorMessage: string;
  isLoading: boolean = true;

  status:boolean = false;
  details:boolean = false;
  Totaldetails:boolean = false;

  sadmin:boolean = false;
  detailsdata:boolean = false;
  isLoadingImage:boolean = false;
  isLoadingImage2:boolean = false;
  isLoadingImage3:boolean = false;

  current_username: string;
  current_laywerCode: string;
  is_admin: boolean = false;

  constructor(
      public rest:AppellatedivisionService, 
      private route: ActivatedRoute, 
      private router: Router,
      public app:AppComponent
  ) { 

    if (!this.app.loggedIn()) {
      this.router.navigate(['../']);
    }  

  }


  ngOnInit() {

    window.scrollTo({
      top: 25000,
      left: 0, 
      behavior: 'smooth'
    });

    this.current_username = this.app.getUserName();
    this.current_laywerCode = this.app.getLawyerCode();
    this.is_admin = this.app.isAdmin(); 

    if(!this.is_admin){
      this.search_code = this.current_laywerCode;
      this.getTotalcaselistad();
      this.getTotalcaselistadcount();
    } 

    if(this.is_admin){
     // this.getTotalcaselisthighcourt();
      this.getTotalcasesummaryLWSadmin();
    } 

  }

  
  getTotalcaselistad() {
    let postData = {
      search_code: this.search_code,
    }

   // console.log(postData);
   this.isLoadingImage = true

    this.rest
        .getTotalcaselistad(postData)
        .subscribe(
          totalcaselisthddata => {
                this.totalcaselisthddata = totalcaselisthddata
                this.isLoading = false
                this.status=true;
                this.Totaldetails=false;
                this.details=false;
                this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImage = false
            }
        );
  }


  getTotalcaselistadcount() {
    let postData = {
      search_code: this.search_code,
    }
 
   // console.log(postData);

    this.rest
        .getTotalcaselistadcount(postData)
        .subscribe(
          totalcaselisthddatacount => {
                this.totalcaselisthddatacount = totalcaselisthddatacount
                this.isLoading = false
                this.status=true;
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }
  
  
  getTotalcaselistaddetails(l_id,case_type_id) {
    //console.log(l_id);
    //console.log(case_type_id);

    //window.scroll(0,520);

    window.scrollTo({
      top: 2000,
      left: 0, 
      behavior: 'smooth'
    });

    this.isLoadingImage2 = true
    this.detailsdata = false

    let postData = {
      l_id: l_id,
      case_type_id: case_type_id,
    }

    //console.log(postData);

   // this.isLoadingImage = true

    this.rest
        .getTotalcaselistaddetails(postData)
        .subscribe(
          totalcaselisthddetailsdata => {
                this.totalcaselisthddetailsdata = totalcaselisthddetailsdata
                this.isLoading = false
                this.details=true;
                this.Totaldetails=false;
                this.isLoadingImage2 = false;
                this.detailsdata = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImage2 = false
            }
        );
  }


  getTotalcaselistCountTW(l_id,case_type_id) {
    //console.log(l_id);
    //console.log(case_type_id);
    let postData = {
      l_id: l_id,
      case_type_id: case_type_id,
    }

    //console.log(postData);

    this.rest
        .getTotalcaselistCountTW(postData)
        .subscribe(
          totalcaselisttwdata => {
                this.totalcaselisttwdata = totalcaselisttwdata
                this.isLoading = false
                this.details=true;
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

  getTotalcasesummaryLW(l_id) {
    //console.log(l_id);
    let postData = {
      l_id: l_id,
    }

    this.isLoadingImage3 = true
    this.detailsdata = false

    window.scrollTo({
      top: 2000,
      left: 0, 
      behavior: 'smooth'
    });
    //console.log(postData);

    this.rest
        .getTotalcasesummaryLW(postData)
        .subscribe(
          totalcasesummarylwdata => {
              //console.log(totalcasesummarylwdata);
                this.totalcasesummarylwdata = totalcasesummarylwdata
                this.isLoading = false
                this.status=true;
                this.Totaldetails=true;
                this.details=false;
                this.isLoadingImage3 = false;
                this.detailsdata = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.status=false;
                this.isLoadingImage3 = false;
            }
        );
  }


  getTotalcasesummaryLWSadmin() {
    let postData = {
      l_id: this.search_code,
    }

   // console.log(postData);

    this.rest
        .getTotalcasesummaryLWSadmin(postData)
        .subscribe(
          totalcasesummarylwdata => {
              //console.log(totalcasesummarylwdata);
                this.totalcasesummarylwdata = totalcasesummarylwdata
                this.isLoading = false
                this.status=true;
                this.sadmin=true;
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }


}
