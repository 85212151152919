import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AppellatedivisionService } from 'src/app/appellatedivision.service';
import { LawyerinfoService } from 'src/app/lawyerinfo.service';

@Component({
  selector: 'app-new-case-request-appelate',
  templateUrl: './new-case-request-appelate.component.html',
  styleUrls: ['./new-case-request-appelate.component.css']
})
export class NewCaseRequestAppelateComponent implements OnInit {
  
  adcasetypedata:any = [];
  //case_type:number;
  case_type: any;
  case_type_text:string;
  case_no_plus:string;
  //case_no:number;
 // year:number;
  //pr:number;

  case_no:any;
  year:any;
  pr:any;

  current_laywerCode:any = [];

  lawyergroupdata:any = [];
  isLoadingImage:boolean = false;

  SuccessMSG: boolean = false;
  

  errorMsg: boolean = false;
  errorMsg2: boolean = false;
  errorMsg3: boolean = false;
  errorMsg4: boolean = false;
  errorMsgDup: boolean = false;
  errorMsgonlineDup: boolean = false;
  //errorMsg5: boolean = false;

  adcaseRequest:any=[];
  adcaseRequestData:any[];

  errorMessage: string;
  isLoading: boolean = true;

  editKey: number=0;


  constructor( 
    public AppellatedivisionService:AppellatedivisionService, 
    private route: ActivatedRoute, 
    public lawyerinfo_api:LawyerinfoService, 
    private router: Router,
    public app:AppComponent
  ) { 

    if (!this.app.loggedIn()) {
      this.router.navigate(['../']);
    }  

  }

  ngOnInit() {

    window.scrollTo({
      top: 1350,
      left: 0, 
      behavior: 'smooth'
    });

    this.current_laywerCode = this.app.getLawyerCode();

    this.adcaseRequestData = JSON.parse(localStorage.getItem("adcaseRequest"));
    this.getCasetype();

    this.getLawyerGroup();

  }

  getCasetype() {
    let postData = {
      //case_type: this.case_type,
    }
    //console.log(postData);

    this.isLoadingImage = true

    this.AppellatedivisionService
        .getCasetype(postData)
        .subscribe(
          adcasetypedata => {
                this.adcasetypedata = adcasetypedata
                this.isLoading = true
                this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImage = false
            }
        );
  }
  
  onAdd(){


    if(this.case_type === undefined || this.case_type == "" || this.case_type == null){
      this.errorMsg = true;
      this.errorMsgonlineDup=false;
      this.errorMsgDup=false; 
      return;
    } 
    else if (this.case_no === undefined || this.case_no == "" || this.case_no == null){
      this.errorMsg  = false;
      this.errorMsgonlineDup=false;
      this.errorMsgDup=false; 
      this.errorMsg2 = true;
      return;
    } 
    else if (this.year === undefined || this.year == "" || this.year == null || this.year.length != 4){
      this.errorMsg  = false;
      this.errorMsg2 = false;
      this.errorMsgonlineDup=false;
      this.errorMsgDup=false; 
      this.errorMsg3 = true;
      //this.errorMsg5 = true;
      return;
    }
    else if (this.pr === undefined || this.pr == "" || this.pr == null){
      this.errorMsg  = false;
      this.errorMsg2 = false;
      this.errorMsg3 = false;
      this.errorMsgonlineDup=false;
      this.errorMsgDup=false; 
      this.errorMsg4 = true;
      return;
    }
/*
    else if (this.year.length != 4 ){
      this.errorMsg  = false;
      this.errorMsg2 = false;
      this.errorMsg3 = false;
      this.errorMsg4 = false;
      this.errorMsg5 = true;
      return;
    }*/


    //this.errorMsg5 = false;
    this.errorMsg4 = false;
    this.errorMsg3 = false;
    this.errorMsg2 = false;
    this.errorMsg  = false;
    this.errorMsgonlineDup=false;
    this.errorMsgDup=false; 


    if(this.editKey>0){
      this.adcaseRequest = JSON.parse(localStorage.getItem("adcaseRequest"));
      this.case_type_text=this.adcaseRequest[this.editKey-1]['case_type_text'];
      this.adcaseRequest.splice(this.editKey-1,1);
      this.editKey=0;
    }
  
    var array = new Object();    
    array["lawyer_code"] = this.current_laywerCode;
    array["case_type"] = this.case_type;
    array["case_type_text"] = this.case_type_text;
    array["case_no_plus"] = this.case_no_plus;
    array["case_no"] = this.case_no;
    array["year"] = this.year;
    array["pr"]=this.pr;

    var postData = {itemcart:  JSON.stringify(array)};
    var filtered = this.adcaseRequest.filter(item => item.lawyer_code === this.current_laywerCode && item.case_type === this.case_type && item.case_no === this.case_no && item.year === this.year  );
    if(filtered.length == 0){
    this.AppellatedivisionService
      .postADcheckduplicate(postData)
      .subscribe(
        caseduplicatedata => {
         console.log(caseduplicatedata.code);
         
         if(caseduplicatedata.code===100){
          this.errorMsgonlineDup=true;
         
         // this.case_type= 0;
          this.case_type_text = "";
          this.case_no_plus = "";
          this.case_no = null;
          this.year = null;
          this.pr= null;
         // this.remarks= null;
          return;    
  
      }else{
        this.adcaseRequest.push(array); 
  
      localStorage.setItem("adcaseRequest", JSON.stringify(this.adcaseRequest));
  
      this.adcaseRequestData = JSON.parse(localStorage.getItem("adcaseRequest"));
     // this.case_type= 0;
      this.case_type_text = "";
      this.case_no_plus = "";
      this.case_no = null;
      this.year = null;
      this.pr= null;
     // this.remarks= null;
  
      }
     
     
  
      
    },
    error => {
        this.errorMessage = <any>error
        this.isLoading = false
    }
  );
  } else{
    this.errorMsgDup=true; 
    //this.case_type= 0;
    this.case_type_text = "";
    this.case_no_plus = "";
    this.case_no = null;
    this.year = null;
    this.pr= null;
   // this.remarks= null;
    return;
  }

    // this.adcaseRequest.push(array);
    
    // localStorage.setItem("adcaseRequest", JSON.stringify(this.adcaseRequest));

    // this.adcaseRequestData = JSON.parse(localStorage.getItem("adcaseRequest"));

    // this.case_type= 0;
    // this.case_type_text = "";
    // this.case_no_plus = "";
    // this.case_no = null;
    // this.year = null;
    // this.pr= null;

  }

  selectChangeHandler (event: Event) {

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    this.case_type_text = selectedOptions[selectedIndex].text;
    this.case_no=null;
    this.year=null;
    
  }

  onDelete(val){
    this.adcaseRequestData = JSON.parse(localStorage.getItem("adcaseRequest"));
    this.adcaseRequestData.splice(val,1)
    this.adcaseRequest.splice(val,1)
    localStorage.setItem("adcaseRequest", JSON.stringify(this.adcaseRequestData));
  }

  onEdit(val){
    //console.log(this.caseRequest[val]);
    this.editKey=val+1;
    this.adcaseRequestData = JSON.parse(localStorage.getItem("adcaseRequest"));
    this.case_type=this.adcaseRequestData[val]['case_type'];
    this.case_no_plus=this.adcaseRequestData[val]['case_no_plus'];
    this.case_no=this.adcaseRequestData[val]['case_no'];
    this.year=this.adcaseRequestData[val]['year'];
    this.pr=this.adcaseRequestData[val]['pr'];
  }

  saveADNewCase() {
    let postData = {
      //case_type: this.case_type,
      allcase: JSON.parse(localStorage.getItem("adcaseRequest")),
    }

    this.SuccessMSG = false

    this.AppellatedivisionService
    .postSaveADCase(postData)
    .subscribe(
      casetypedata => {
            this.isLoading = true
            this.adcaseRequestData = [];
            this.adcaseRequest=[];
            localStorage.removeItem("adcaseRequest");
            this.SuccessMSG = true
        },
        error => {
            this.errorMessage = <any>error
            this.isLoading = false
        }
    );
  }

  getLawyerGroup() {
    let postData = {
      lawyerCode: this.current_laywerCode
    }
    
    this.isLoadingImage = true

    this.lawyerinfo_api
        .getLawyerGroup(postData)
        .subscribe(
            lawyergroupdata => {
                this.lawyergroupdata = lawyergroupdata
                this.isLoading = false;
                this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false;
                this.isLoadingImage = false
            }
        );
  }


}
