import { Component, OnInit } from '@angular/core';
import { CauselistsearchService } from '../../../causelistsearch.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cause-list-search-page-history',
  templateUrl: './cause-list-search-page-history.component.html',
  styleUrls: ['./cause-list-search-page-history.component.css']
})
export class CauseListSearchPageHistoryComponent implements OnInit {

  causelisthistorybypagedata:any = [];
  casetypetitle:any = [];
  court_title:any = [];
  justice_title:any = [];

  casetypeid: any;
  caseno: any;
  caseyear: any;

  errorMessage: string;
  isLoading: boolean = true;
  isLoadingImg: boolean = true;

  case_type_id: number;
  case_no: number;
  case_year: number;
  court_id: number;
  justice_id: number;
  page_no: number;
  dateval: string;  

  private sub: any;

  constructor(
    public rest:CauselistsearchService, 
    private route: ActivatedRoute, 
    private router: Router
  ) { }
  
  ngOnInit() {

    
    window.scrollTo({
      top: 1350,
      left: 0, 
      behavior: 'smooth'
    });

    this.sub = this.route.params.subscribe(params => {
      this.page_no = +params['page_no'];
      this.justice_id = +params['justice_id'];
      this.court_id = +params['court_id'];
      this.dateval = params['date_val'];
      this.case_type_id = +params['case_type_id'];
      this.case_no = +params['case_no'];
      this.case_year = +params['case_year'];
    });

    this.getCauselistHistoryByPage();
  } 

  getCauselistHistoryByPage() {
    let postData = {
      casetypeid: this.case_type_id,
      caseno: this.case_no,
      caseyear: this.case_year,
      court_id: this.court_id,
      page_no: this.page_no,
      dateval: this.dateval
    }
    //console.log(postData);

    this.rest
        .getCauseListHistoryByPageService(postData)
        .subscribe(
          causelisthistorybypagedata => {
              this.causelisthistorybypagedata = causelisthistorybypagedata
              this.isLoading = false
              this.isLoadingImg = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );

    // Get Justice Title
    this.rest
        .getJusticeTitle(this.justice_id)
        .subscribe(
          justice_title => {
              this.justice_title = justice_title
            }
        );

    // Get Case Type Title
    this.rest
        .getCasetypeTitle(this.case_type_id)
        .subscribe(
          casetypetitle => {
              this.casetypetitle = casetypetitle
              this.isLoading = false
            }
        );

    // Get Court Title
    this.rest
        .getCourtTitle(this.court_id)
        .subscribe(
          court_title => {
              this.court_title = court_title
            }
        );


  }

}
