import { Component, OnInit } from '@angular/core';
import { AppellatedivisionService } from 'src/app/appellatedivision.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ad-cause-list-search',
  templateUrl: './ad-cause-list-search.component.html',
  styleUrls: ['./ad-cause-list-search.component.css']
})
export class AdCauseListSearchComponent implements OnInit {

  selectedCaseType: string = '';
  selectedCaseTypeValue: number;
   
  selectChangeHandler (event: Event) {

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    this.selectedCaseType = selectedOptions[selectedIndex].text;
    this.selectedCaseTypeValue = selectedOptions[selectedIndex].value;
    
    // console.log(this.selectedCaseType);
    // console.log(this.selectedCaseTypeValue);
  }


  casetypedata:any = [];
  causelistdata:any = [];
  causelisthistorydata:any = [];
  casetypeid: any;
  caseno: any;
  caseyear: any;

  errorMsg: boolean = false;
  errorMsg2: boolean = false;
  errorMsg3: boolean = false;
  //errorMsg4: boolean = false;

  errorMessage: string;
  isLoading: boolean = true;
  isLoadingImage: boolean = false;
  detailsdata:boolean = false;

  constructor(
      public rest: AppellatedivisionService, 
      private route: ActivatedRoute, 
      private router: Router
  ) { }

  ngOnInit() {
    this.getCasetype();

    
    window.scrollTo({
      top: 1350,
      left: 0, 
      behavior: 'smooth'
    });
  }


  getCasetype() {
    let postData = {
      casetypeid: this.casetypeid,
    }

    this.rest
        .getCasetype(postData)
        .subscribe(
          casetypedata => {
                this.casetypedata = casetypedata
                this.isLoading = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }


  getCauselistad() {
    let postData = {
      casetypeid: this.casetypeid,
      caseno: this.caseno,
      caseyear: this.caseyear,
    }
    
    if(this.casetypeid === undefined || this.casetypeid == "" || this.casetypeid == null){
      this.errorMsg2 = true;
      return;
    } 
    else if (this.caseno === undefined || this.caseno == "" || this.caseno == null){
      this.errorMsg2 = false;
      this.errorMsg  = true;
      return;
    } 
    else if (this.caseyear === undefined || this.caseyear == "" || this.caseyear == null || this.caseyear.length != 4){
      this.errorMsg  = false;
      this.errorMsg2 = false;
      this.errorMsg3 = true;
      return;
    } 
    /* 
    else if (this.caseyear.value.length != 4){
      this.errorMsg  = false;
      this.errorMsg2 = false;
      this.errorMsg3 = false;
      this.errorMsg4 = true;
      return;
    }*/

    //this.errorMsg4 = false;
    this.errorMsg3 = false;
    this.errorMsg2 = false;
    this.errorMsg  = false;
    

    //console.log(postData);

    window.scrollTo({
      top: 700,
      left: 0, 
      behavior: 'smooth'
    });

    this.isLoadingImage = true
    this.detailsdata = false

    this.rest
        .getCauselistad(postData)
        .subscribe(
          causelistdata => {
          // console.log(courtwisedata);
              this.causelistdata = causelistdata
              this.isLoading = false
              this.isLoadingImage = false
              this.detailsdata = true
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImage = false
            }
        );
  }


  getCauselisthistoryad() {
    let postData = {
      casetypeid: this.casetypeid,
      caseno: this.caseno,
      caseyear: this.caseyear,
    }
    //console.log(postData);

    this.rest
        .getCauselisthistoryad(postData)
        .subscribe(
          causelisthistorydata => {
          // console.log(courtwisedata);
              this.causelisthistorydata = causelisthistorydata
              this.isLoading = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

}
