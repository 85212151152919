import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    //  if (screen.width < 768) {
    //     window.location.href = "http://m.bdlawservice.com/";
    //   }
  }

}
