import { Component, OnInit } from '@angular/core';
import { AppellatedivisionService } from '../../../appellatedivision.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ad-cause-list-search-history',
  templateUrl: './ad-cause-list-search-history.component.html',
  styleUrls: ['./ad-cause-list-search-history.component.css']
})
export class AdCauseListSearchHistoryComponent implements OnInit {

  causelisthistorydata:any = [];
  casetypetitle:any = [];
  
  casetypeid: any;
  caseno: any;
  caseyear: any;

  errorMessage: string;
  isLoading: boolean = true;
  isLoadingImg: boolean = true;

  case_type_id: number;
  case_no: number;
  case_year: number;
  private sub: any;

  constructor(
    public rest:AppellatedivisionService, 
    private route: ActivatedRoute, 
    private router: Router
) { }

  ngOnInit() {

    
    window.scrollTo({
      top: 1350,
      left: 0, 
      behavior: 'smooth'
    });

    this.sub = this.route.params.subscribe(params => {
      this.case_type_id = +params['case_type_id'];
      this.case_no = +params['case_no'];
      this.case_year = +params['case_year'];
    });

    this.getCauselisthistoryad();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getCauselisthistoryad() {
    let postData = {
      casetypeid: this.case_type_id,
      caseno: this.case_no,
      caseyear: this.case_year,
    }
    //console.log(postData);

    this.rest
        .getCauselisthistoryad(postData)
        .subscribe(
          causelisthistorydata => {
              this.causelisthistorydata = causelisthistorydata
              this.isLoading = false
              this.isLoadingImg = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
                this.isLoadingImg = false
            } 
        );

    this.rest
        .getCasetypeTitle(this.case_type_id)
        .subscribe(
          casetypetitle => {
              this.casetypetitle = casetypetitle
              this.isLoading = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }

}
