import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtService } from '../jwt.service';
import { AppComponent } from '../app.component';
import { LawyerinfoService } from '../lawyerinfo.service';
import { isNull } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-rightsidebar',
  templateUrl: './rightsidebar.component.html',
  styleUrls: ['./rightsidebar.component.css']
})
export class RightsidebarComponent implements OnInit {

  userdata:any = [];
  lawyerinfodata:any = [];
  username: any;
  password: any;

  errorMessage: string;
  isLoading: boolean = false;

  is_loggedin: boolean = true;
  isLogin: boolean = true;
  isLogout: boolean = true;

  current_laywerCode: string;

  totalnotification:any = [];

  constructor(
    public rest:JwtService, 
    private route: ActivatedRoute, 
    private router: Router,
    public app:AppComponent,
    public lawyerinfo_api:LawyerinfoService, 
) { }

  ngOnInit() {

    if(this.app.loggedIn()){
      this.is_loggedin = true;
    } else {
      this.is_loggedin = false;
    }

    this.current_laywerCode = this.app.getLawyerCode();

    if(this.current_laywerCode != null && this.current_laywerCode != "sadmin"){
      this.getLawyerInfo(this.current_laywerCode);
      this.gettotalnotification();
    }

  }

  actionLogin() {
    let postData = {
      username: this.username,
      name: this.password
    }
    //console.log(postData);
    
    this.isLoading = true;

    this.rest
        .login(this.username, this.password)
        .subscribe(
          userdata => {
            console.log(userdata);
              this.userdata = userdata;
              this.lawyerinfodata = '';
              this.isLogin = false;
              this.isLogout = true;
              this.is_loggedin = true;
              this.isLoading = false;
              this.errorMessage = '';
              this.username = '';
              this.password = '';

              
              location.reload();
              
            },
            error => {
                this.errorMessage = <any>error
                this.isLogin = true
                this.isLogout = false
                this.is_loggedin = false;
                this.isLoading = false;
            }
        );

    
      
  }

  actionLogout() {

    this.isLoading = true;

    this.rest.logout();

    this.isLogin = true
    this.isLogout = false
    this.is_loggedin = false;
    this.isLoading = false;

    location.reload();

  }

  getLawyerInfo(lawyerCode) {

    let postData = {
      lawyerCode: lawyerCode
    }
    
    this.lawyerinfo_api
            .getLawyerinfo(postData)
            .subscribe(
                lawyerinfodata => {
                    this.lawyerinfodata = lawyerinfodata
                }
            );
  }

  gettotalnotification() {
    let postData = {
      current_laywerCode:  this.current_laywerCode,
    }
    //console.log(this.current_laywerCode);

    this.lawyerinfo_api
        .gettotalnotification(postData)
        .subscribe(
          totalnotification => {
              this.totalnotification = totalnotification
            },
            error => {
                this.errorMessage = <any>error
            }
        );
  }


}
