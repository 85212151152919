import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomecontentComponent } from './pages/homecontent/homecontent.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { JusticeWiseComponent } from './pages/case_list/justice-wise/justice-wise.component';
import { CourtWiseComponent } from './pages/case_list/court-wise/court-wise.component';
import { AdCourtWiseComponent } from './pages/case_list/ad-court-wise/ad-court-wise.component';
import { AdPageWiseComponent } from './pages/case_list/ad-page-wise/ad-page-wise.component';
import { CauseListSearchComponent } from './pages/search_your_case/cause-list-search/cause-list-search.component';
import { AdCauseListSearchComponent } from './pages/search_your_case/ad-cause-list-search/ad-cause-list-search.component';
import { OurServicesComponent } from './pages/our_services/our-services/our-services.component';
import { SoftwareAndWebDevelopmenComponent } from './pages/our_services/software-and-web-developmen/software-and-web-developmen.component';
import { AdvocateComponent } from './pages/service_user/advocate/advocate.component';
import { OrganizationAndOthersComponent } from './pages/service_user/organization-and-others/organization-and-others.component';
import { SearchListByDateHighcourtComponent } from './pages/search_list_user_menu/search-list-by-date-highcourt/search-list-by-date-highcourt.component';
import { SearchListByDateAppelateComponent } from './pages/search_list_user_menu/search-list-by-date-appelate/search-list-by-date-appelate.component';
import { TotalCaseListHighcourtComponent } from './pages/search_list_user_menu/total-case-list-highcourt/total-case-list-highcourt.component';
import { TotalCaseListAppelateComponent } from './pages/search_list_user_menu/total-case-list-appelate/total-case-list-appelate.component';
import { NewCaseRequestHighcourtComponent } from './pages/search_list_user_menu/new-case-request-highcourt/new-case-request-highcourt.component';
import { NewCaseRequestAppelateComponent } from './pages/search_list_user_menu/new-case-request-appelate/new-case-request-appelate.component';
import { UserDetailsComponent } from './pages/search_list_user_menu/user-details/user-details.component';
import { CauseListSearchHistoryComponent } from './pages/search_your_case/cause-list-search-history/cause-list-search-history.component';
import { CauseListSearchCourtHistoryComponent } from './pages/search_your_case/cause-list-search-court-history/cause-list-search-court-history.component';
import { CauseListSearchPageHistoryComponent } from './pages/search_your_case/cause-list-search-page-history/cause-list-search-page-history.component';
import { AdCauseListSearchHistoryComponent } from './pages/search_your_case/ad-cause-list-search-history/ad-cause-list-search-history.component';
import { AdCauseListSearchCourtHistoryComponent } from './pages/search_your_case/ad-cause-list-search-court-history/ad-cause-list-search-court-history.component';
import { AdCauseListSearchPageHistoryComponent } from './pages/search_your_case/ad-cause-list-search-page-history/ad-cause-list-search-page-history.component';
import{ ScrollNewsComponent } from './pages/scroll-news/scroll-news.component';
import{ HighcourtComponent } from './pages/highcourt/highcourt.component';
import{ AppellateComponent } from './pages/appellate/appellate.component';

const routes: Routes = [
  { path: 'home', component: HomecontentComponent },
  { path: '', component: HomecontentComponent },
  { path: 'about', component: AboutComponent },
  { path: 'highcourt', component: HighcourtComponent },
  { path: 'appellate', component: AppellateComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'notification', component: NotificationComponent },
  { path: 'highcourt/justice_wise', component: JusticeWiseComponent },
  { path: 'highcourt/court-wise', component: CourtWiseComponent },
  { path: 'appellate/ad-court-wise', component: AdCourtWiseComponent },
  { path: 'appellate/ad-page-wise', component: AdPageWiseComponent },
  { path: 'cause-list-search', component: CauseListSearchComponent },
  { path: 'ad-cause-list-search', component: AdCauseListSearchComponent },
  { path: 'our-services', component: OurServicesComponent },
  { path: 'software-and-web-developmen', component: SoftwareAndWebDevelopmenComponent },
  { path: 'advocates', component: AdvocateComponent },
  { path: 'organization-and-others', component: OrganizationAndOthersComponent },
  { path: 'search-list-by-date-highcourt', component: SearchListByDateHighcourtComponent },
  { path: 'search-list-by-date-appelate', component: SearchListByDateAppelateComponent },
  { path: 'total-case-list-highcourt', component: TotalCaseListHighcourtComponent },
  { path: 'total-case-list-appelate', component: TotalCaseListAppelateComponent },
  { path: 'new-case-request-highcourt', component: NewCaseRequestHighcourtComponent },
  { path: 'new-case-request-appelate', component: NewCaseRequestAppelateComponent },
  { path: 'user-details', component: UserDetailsComponent },
  { path: 'scroll-news', component: ScrollNewsComponent },
  { path: 'cause-list-search/cause-list-search-history/:case_type_id/:case_no/:case_year', component: CauseListSearchHistoryComponent },
  { path: 'cause-list-search/cause-list-search-court-history/:justice_id/:court_id/:date_val/:case_type_id/:case_no/:case_year', component: CauseListSearchCourtHistoryComponent },
  { path: 'cause-list-search/cause-list-search-page-history/:page_no/:justice_id/:court_id/:date_val/:case_type_id/:case_no/:case_year', component: CauseListSearchPageHistoryComponent },
  { path: 'ad-cause-list-search/ad-cause-list-search-history/:case_type_id/:case_no/:case_year', component: AdCauseListSearchHistoryComponent },
  { path: 'ad-cause-list-search/ad-cause-list-search-court-history/:justice_id/:court_id/:date_val/:case_type_id/:case_no/:case_year', component: AdCauseListSearchCourtHistoryComponent },
  { path: 'ad-cause-list-search/ad-cause-list-search-page-history/:page_no/:justice_id/:court_id/:date_val/:case_type_id/:case_no/:case_year', component: AdCauseListSearchPageHistoryComponent }
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  declarations: [],
  exports: [ RouterModule ]
})
export class AppRoutingModule { 
  
}